<template lang="html">
  <vue-apex-charts
    type="donut"
    height="270"
    :options="chartOptions"
    :series="seriesData"
  />
</template>

<script>
/* eslint-disable */
import VueApexCharts from 'vue-apexcharts'

  export default {
    name: "GraficoDonut",

    components: {
        VueApexCharts,
    },

    props: {
        series: []
    },

    computed: {

    windowWidth() {
        return this.$store.state.app.windowWidth
    },

    seriesData(){
        return Object.values(this.series).map((item) => item.percentualParticipacao);
    },

    chartOptions() {
        let _self = this
        let config =
            {
                //colors: ['#5b3cc4','rgb(64, 138, 81)','rgb(242, 19, 93)'],
                labels: Object.keys(_self.series),
                dataLabels: {
                    enabled: true,
                    formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}%`
                    },
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '2rem',
                                    fontFamily: 'Montserrat',
                                },
                                value: {
                                    fontSize: '1rem',
                                    fontFamily: 'Montserrat',
                                    formatter(val) {
                                    // eslint-disable-next-line radix
                                    return `${parseInt(val)}%`
                                    },
                                },
                                total: {
                                    show: false,
                                    fontSize: '1.5rem',
                                    label: 'Maior Participação',
                                    formatter() {
                                        return '31%'
                                    },
                                },
                            },
                        }

                    }
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    fontSize: '14px',
                    fontFamily: 'Montserrat',
                },
            }
        return config
    },
    }
  }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/chart-apex.scss';
</style>