<template>
  <div>
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-row>
        <b-col cols="6">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.dataInicioBT }}
                  </h2>
                  <span>Data Início</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.dataFimBT }}
                  </h2>
                  <span>Data Fim</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="6">
          <b-card>
            <b-row>
              <div class="d-flex flex-wrap">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  class="icon-card cursor-pointer text-center mx-50"
                  @click="confirmarExecutar(carteira.id)"
                >
                  <div class="icon-wrapper">
                    <feather-icon
                      icon="PlayIcon"
                    />
                  </div>
                  <p class="card-text icon-name text-truncate mb-0 mt-2">
                    Re-executar
                  </p>
                </b-button>
              </div>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="carteira.id">
        <b-col cols="12">
          <b-card-actions
            :title="'ID ' + carteira.id"
            action-collapse
          >
            <b-row>
              <b-col cols="6">
                <b-col cols="12">
                  <label
                    for="rating-lg-no-border"
                    class="mr-1"
                  >
                    Score
                  </label>
                  <b-form-rating
                    id="rating-lg-no-border"
                    v-model="carteira.score"
                    no-border
                    inline
                    variant="warning"
                    size="lg"
                    stars="20"
                    show-value
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <div class="form-label-group">
                    <b-form-textarea
                      id="textarea"
                      v-model="carteira.observacao"
                      rows="3"
                      placeholder="Observação"
                    />
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="salvarDados"
                  >
                    Salvar
                  </b-button>
                </b-col>
              </b-col>
              <b-col cols="6">
                <vue-json-pretty
                  :data="dadosCarteira"
                  :virtual="true"
                  height="250"
                />
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>

        <b-col
          v-for="grafico in graficos"
          :key="grafico.id"
          cols="12"
        >
          <b-card :title="grafico.nome">
            <div class="chart-container">
              <LWChart
                :grafico="grafico"
                :series="grafico.series"
                :series-update="grafico.series"
              />
            </div>
          </b-card>
        </b-col>
        <b-col
          v-if="carteira.distribuicaoEstrategias"
          cols="12"
        >
          <b-card title="Distribuição Estratégias">
            <grafico-donut
              :series="JSON.parse(carteira.distribuicaoEstrategias)"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.depositoInicial, 2, true) }}
                  </h2>
                  <span>Depósito</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.lucroLiquido, 2, true) }}
                  </h2>
                  <span>Lucro Líquido</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.rentabilidadePeriodo, 2) }}<span class="font-medium-3">%</span>
                  </h2>
                  <span>Rentabilidade Período</span>
                </div>
              </b-col>
              <b-col
                xl="3"
                sm="6"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.rentabilidadeMensalMedia, 2) }}<span class="font-medium-3">%</span>
                  </h2>
                  <span>Rentabilidade Média Mensal</span>
                </div>
              </b-col>
              <b-col
                xl="3"
                sm="6"
              >
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdMesesNegativos }}
                  </h2>
                  <span>Qtd Meses Negativo</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.expectativaMatematica, 2) }}
                  </h2>
                  <span>Expectativa</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.indiceSharpe, 2) }}
                  </h2>
                  <span>Índice Sharpe</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.pontuacaoFuncaoBackTeste) }}
                  </h2>
                  <span>Pontuação</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.fatorLucro, 2, true) }}
                  </h2>
                  <span>Fator Lucro</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.lucroBruto, 2, true) }}
                  </h2>
                  <span>Lucro Bruto</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.perdaBruta, 2, true) }}
                  </h2>
                  <span>Perda Bruta</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.custosOperacionais, 2, true) }}
                  </h2>
                  <span>Custos Operacionais</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.payoff, 2) }}
                  </h2>
                  <span>Payoff</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.mediaLucroOperacoes, 2, true) }}
                  </h2>
                  <span>Média Lucro <br> por Operação</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.mediaPerdaOperacoes, 2, true) }}
                  </h2>
                  <span>Média Perda <br> por Operação</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="8">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.taxaAcerto }}<span class="font-medium-3">%</span>
                  </h2>
                  <span>Taxa Acerto</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.taxaErro }}<span class="font-medium-3">%</span>
                  </h2>
                  <span>Taxa Erro</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdTotalOperacoes }}
                  </h2>
                  <span>Qtd Total Operações</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdOperacoesLong }} <span class="font-medium-3">({{ carteira.percentualOperacoesLong }}%)</span>
                  </h2>
                  <span>Operações Long</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdOperacoesShort }} <span class="font-medium-3">({{ carteira.percentualOperacoesShort }}%)</span>
                  </h2>
                  <span>Operações Short</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdGanhosConsecutivos }}
                  </h2>
                  <span>Qtd Ganhos Consecutivos</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.qtdPerdasConsecutivas }}
                  </h2>
                  <span>Qtd Perdas Consecutivas</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="4">
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.operacaoMaiorDuracaoHoras }}
                  </h2>
                  <span>Operação Maior Duração (Horas)</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.duracaoMediaHoras }}
                  </h2>
                  <span>Duração Média (Horas)</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ carteira.maiorPeriodoEstagnacaoDias }} dias <br>
                    <small>até
                      <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(carteira.timeMaiorPeriodoEstagnacaoDias)) }}</span>
                    </small>
                  </h2>
                  <span>Maior Estagnação</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.maiorDrawDownSaldoAbsoluto, 2, true) }}  <br>
                    <small>em
                      <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(carteira.timeMaiorDrawDownSaldoAbsoluto)) }}</span>
                    </small>
                  </h2>
                  <span>Maior DrawDown <br> Saldo Abs</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.maiorDrawDownSaldoPercentual, 2) }}<span class="font-medium-3">%</span> <br>
                    <small>em
                      <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(carteira.timeMaiorDrawDownSaldoPercentual)) }}</span>
                    </small>
                  </h2>
                  <span>Maior DrawDown <br> Saldo %</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.maiorDrawDownCapitalLiquidoAbsoluto, 2, true) }} <br>
                    <small>em
                      <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(carteira.timeMaiorDrawDownCapitalLiquidoAbsoluto)) }}</span>
                    </small>
                  </h2>
                  <span>Maior DrawDown <br>
                    Capital Líquido Abs</span>
                </div>
              </b-col>
              <b-col>
                <div class="truncate">
                  <h2 class="mb-25 font-weight-bolder">
                    {{ formataValor(carteira.maiorDrawDownCapitalLiquidoPercentual, 2) }}<span class="font-medium-3">%</span> <br>
                    <small>em
                      <span class="font-weight-bolder">{{ formatDateTimeSegundoBR(new Date(carteira.timeMaiorDrawDownCapitalLiquidoPercentual)) }}</span>
                    </small>
                  </h2>
                  <span>Maior DrawDown <br> Capital %</span>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card title="Risco Ruína">
            <b-table
              :items="JSON.parse(carteira.riscoRuina)"
              responsive
              thead-class="hidden_header"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro encontrado"
              striped
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card title="Rentabilidade Mensal">
            <b-table
              :items="JSON.parse(carteira.rentabilidadeMensal)"
              responsive
              :fields="tableRentabilidadeMensal"
              primary-key="id"
              show-empty
              empty-text="Nenhum registro encontrado"
              striped
            >
              <template #cell(rentabilidade)="data">
                {{ formataValor(data.value, 2) }}%
              </template>

              <template #cell(saldoInicial)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(saldoFinal)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(perdas)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(lucros)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(taxaAcertoMes)="data">
                {{ formataValor(data.value, 2) }}%
              </template>

              <template #cell(detalhe)="row">
                <b-button
                  v-model="row.detailsShowing"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="row.toggleDetails"
                >
                  {{ row.detailsShowing ? 'Fechar' : 'Mostrar' }}
                </b-button>
              </template>

              <template #row-details="row">
                <b-card title="Distribuição Estratégias">
                  <b-row class="mb-2">
                    <grafico-donut
                      :series="JSON.parse(row.item.distribuicaoEstrategias)"
                    />
                  </b-row>
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Fechar
                  </b-button>
                </b-card>
              </template>

            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="carteira.operacoes">
        <b-col>
          <b-card title="Operações">
            <b-table
              ref="refListTableOperacoes"
              :items="itemsOperacoes"
              :per-page="perPageOperacao"
              :current-page="currentPageOperacao"
              responsive
              :fields="tableOperacoes"
              primary-key="bilhete"
              show-empty
              empty-text="Nenhum registro encontrado"
              striped
              sort-by.sync="bilhete"
              sort-desc.sync="false"
            >

              <template #cell(maiorLucroAberto)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(timeMaiorLucroAberto)="data">
                {{ formatDateTimeSegundoBR(data.value) }}
              </template>

              <template #cell(maiorPrejuizoAberto)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(timeMaiorPrejuizoAberto)="data">
                {{ formatDateTimeSegundoBR(data.value) }}
              </template>

              <template #cell(percentualSobreSaldo)="data">
                {{ formataValor(data.value, 2) }}%
              </template>

              <template #cell(timeSaida)="data">
                {{ formatDateTimeSegundoBR(data.value) }}
              </template>

              <template #cell(duracao)="data">
                {{ getDuracaoHoraMinuto(data.value) }}
              </template>

              <template #cell(pnl)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(pnlLiquido)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(custoTotal)="data">
                {{ formataValor(data.value, 2, true) }}
              </template>

              <template #cell(detalhe)="row">
                <b-button
                  v-model="row.detailsShowing"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="row.toggleDetails"
                >
                  {{ row.detailsShowing ? 'Fechar' : 'Mostrar' }}
                </b-button>

              </template>

              <template #row-details="row">
                <b-card title="Ordens">
                  <b-row class="mb-2">
                    <b-table
                      :fields="tableOperacoesOrdens"
                      responsive
                      sort-by.sync="time"
                      sort-desc.sync="false"
                      :items="row.item.entradas.concat(row.item.saidas)"
                    >
                      <template #cell(time)="data">
                        {{ formatDateTimeSegundoBR(data.value) }}
                      </template>
                    </b-table>
                  </b-row>
                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Fechar
                  </b-button>
                </b-card>
              </template>

            </b-table>

            <div class="mx-2 mb-2">
              <b-row>

                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted">Mostrando {{ paginationMetaOperacoes.from }} a {{ paginationMetaOperacoes.to }} de {{ paginationMetaOperacoes.of }} registros</span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >

                  <b-pagination
                    v-model="currentPageOperacao"
                    :total-rows="itemsTotalOperacoes"
                    :per-page="perPageOperacao"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>

                </b-col>

              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>

import {
  BRow, BCard, BCol, BTable, BPagination, BButton, BFormRating, BFormTextarea, BFormInput, BForm, BOverlay,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import store from '@/store'
import { formatDateTimeSegundoBR } from '@/utils/filter'
import utilsMixin from '@/mixins/utilsMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import LWChart from '@/layouts/components/custom/LWChart2.vue'
import GraficoDonut from '@/layouts/components/custom/GraficoDonut.vue'
import Ripple from 'vue-ripple-directive'
import carteiraStoreModule from './carteiraStoreModule'

const CARTEIRA_STORE_MODULE_NAME = 'carteira'

/* eslint-disable */

export default {

  components: {
    VueJsonPretty,
    LWChart,
    BRow,
    BCard,
    BCol,
    BTable,
    BCardActions,
    BPagination,
    BButton,
    BFormRating,
    BFormInput,
    BFormTextarea,
    BForm,
    BOverlay,
    GraficoDonut,
  },

  directives: {
    Ripple,
  },

  mixins: [utilsMixin],

  data() {
    return {
      formatDateTimeSegundoBR,
      carteira: [],
      loadingOverlay: false,
      tableRentabilidadeMensal: [
        { key: 'mesAno', label: 'Mês/Ano' },
        { key: 'rentabilidade', label: 'Rentabilidade' },
        { key: 'saldoInicial', label: 'Saldo Inicial' },
        { key: 'saldoFinal', label: 'Saldo Final' },
        { key: 'perdas', label: 'Perdas' },
        { key: 'lucros', label: 'Lucros' },
        { key: 'qtdGanhosMes', label: 'Qtd Ganhos' },
        { key: 'qtdPerdasMes', label: 'Qtd Perdas' },
        { key: 'qtdTotalOperacoes', label: 'Qtd Total Operações' },
        { key: 'taxaAcertoMes', label: 'Taxa Acerto' },
        { key: 'detalhe', label: 'Detalhes'},
      ],

      tableOperacoesOrdens: [
        { key: 'time', label: 'Data'},
        { key: 'direcao', label: 'Direção'},
        { key: 'lote', label: 'Lote'},
        { key: 'preco', label: 'Preço'},
      ],

      tableOperacoes: [
        { key: 'bilhete', label: 'ID', sortable: true },
        { key: 'symbol', label: 'Ativo', sortable: true },
        { key: 'nomeEstrategia', label: 'Estratégia', sortable: true },
        { key: 'percentualSobreSaldo', label: 'Percent. Sobre saldo', sortable: true },
        { key: 'direcao', label: 'Direção', sortable: true },
        { key: 'timeSaida', label: 'Data Saída', sortable: true },
        { key: 'duracao', label: 'Duração', sortable: true },
        { key: 'pnl', label: 'PNL', sortable: true },
        { key: 'pnlLiquido', label: 'PNl Líquido', sortable: true },
        { key: 'custoTotal', label: 'Custo Total', sortable: true },
        { key: 'detalhe', label: 'Detalhes'},
      ],
      currentPageOperacao: 1,
      perPageOperacao: 50,
    }
  },

  computed: {

    dadosCarteira() {
      if(!this.carteira.dadosCarteira)
        return null
      return JSON.parse(this.carteira.dadosCarteira)
    },

    graficos() {
      if(!this.carteira.plotResultado)
        return null
      return JSON.parse(this.carteira.plotResultado).graficos
    },

    itemsOperacoes() {
      return JSON.parse(this.carteira.operacoes)
    },

    itemsTotalOperacoes() {
      return this.itemsOperacoes.length
    },

    paginationMetaOperacoes() {
      const localItemsCount = this.$refs.refListTableOperacoes ? this.$refs.refListTableOperacoes.localItems.length : 0
      return {
        from: this.perPageOperacao * (this.currentPageOperacao - 1) + (localItemsCount ? 1 : 0),
        to: this.perPageOperacao * (this.currentPageOperacao - 1) + localItemsCount,
        of: this.itemsTotalOperacoes,
      }

    },

  },

  watch: {
  },

  created() {
    if (!store.hasModule(CARTEIRA_STORE_MODULE_NAME)) store.registerModule(CARTEIRA_STORE_MODULE_NAME, carteiraStoreModule)
    this.getCarteira(this.$route.params.id)
  },

  beforeDestroy() {
    if (store.hasModule(CARTEIRA_STORE_MODULE_NAME)) store.unregisterModule(CARTEIRA_STORE_MODULE_NAME)
  },

  methods: {

    salvarDados() {
      let payload = {
        idCarteira: this.carteira.id,
        score: this.carteira.score,
        observacao: this.carteira.observacao
      }

      this.$store.dispatch('carteira/atualizar', payload )
        .then(response => {
          this.$swal({
            title: 'Sucesso!',
            text: 'Carteira atualizada com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao atualizar a carteira',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    executar(id) {
      this.loadingOverlay = true
      this.$store.dispatch('carteira/executar', { idCarteira: id })
      .then(() => {
        this.$swal({
          icon: 'success',
          title: 'Carteira executada',
          text: 'Sua carteira foi executada',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      })
      .finally(() => {
        this.loadingOverlay = false
      })
    },

    confirmarExecutar(id) {
      this.$swal({
        title: 'Tem certeza que deseja re-executar?',
        text: 'Sua carteira será re-executada',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode re-executar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.executar(id)
        }
      })
    },

    getCarteira(id) {
      this.$store.dispatch('carteira/getCarteira', id)
        .then(response => {
          this.carteira = response.data
        })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Registro não encontrado',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/base/bootstrap-extended/include';
@import '@core/scss/base/components/variables-dark';
  .dark-layout {
    div ::v-deep {
        .b-overlay {
          .bg-light {
            background-color: $theme-dark-body-bg !important;
          }
        }
    }
  }
</style>
<style>
.hidden_header {
  display: none;
}
</style>